export const environment = {
  production: false,
  BLUEPRINT_URL: 'https://widgets.risevision.com/staging/templates/PRODUCT_CODE/blueprint.json',
  canvaApiKey: 'EwLWFws4Qjpa-n_2ZJgBMQbz',
  // AngularJS config
  CORE_URL: 'https://api-test.risevision.com/_ah/api',
  STORE_ENDPOINT_URL: 'https://api-test.risevision.com/store/_ah/api',
  STORE_SERVER_URL: 'https://api-test.risevision.com/store/',
  VIEWER_URL: 'https://widgets.risevision.com/viewer-stage-0',
  SHARED_SCHEDULE_URL: 'https://widgets.risevision.com/viewer-stage-0/?type=sharedschedule&id=SCHEDULE_ID',
  ALERTS_WS_URL: 'https://api-test.risevision.com/alerts/cap',
  TAG_MANAGER_CONTAINER_ID: 'GTM-MMTK3JH',
  TAG_MANAGER_AUTH: 'Ry3lxk_Xrlx2qhbXmLA-Pg',
  TAG_MANAGER_ENV: 'env-254',
  SCREEN_SHARING_SERVICE_URL: 'wss://services2-stage.risevision.com/screensharing-2/',
  MESSAGING_PRIMUS_URL: 'https://services-stage.risevision.com/messaging/primus?displayId=apps&machineId=1',
  ENV_NAME: 'STAGE',
  OAUTH_TOKEN_PROVIDER_URL: 'https://services-stage.risevision.com/oauthtokenprovider/',
  TWITTER_SERVICE_URL: 'https://services-stage.risevision.com/twitter/',
  GOOGLE_SERVICE_URL: 'https://services-stage.risevision.com/google/',
  DATA_SERVICE_URL: 'https://services-stage.risevision.com/data/',
  POWERBI_SERVICE_URL: 'https://services-stage.risevision.com/data/ms-pbi/',
  MICROSOFT_OUTLOOK_SERVICE_URL: 'https://services-stage.risevision.com/data/ms-graph/calendar/',
  MICROSOFT_ONEDRIVE_SERVICE_URL: 'https://services-stage.risevision.com/data/ms-graph/onedrive/',
  HUBSPOT_ACCOUNT: '2939619',
  STRIPE_API_KEY: 'pk_test_GrMIAHSoqhaik4tcHepsxjOR',
  HTML_TEMPLATE_URL: 'https://widgets.risevision.com/staging/templates/PRODUCT_CODE/src/template.html',
  SSO_LOGIN_URL: 'https://api-test.risevision.com/sso/login',
  SPRING_ENDPOINT_URL: 'https://api-test.risevision.com/api/core',
  SPRING_STORAGE_ENDPOINT_URL: 'https://api-test.risevision.com/storage/api/storage',
  PIXABAY_API_KEY: '42941787-c47b7eb6f834a8cdbc497637d'
};
